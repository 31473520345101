<template lang="html">
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="giftbox"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="exampleModalLabel"
            v-html="giftbox.name"
          ></h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div v-if="typeof giftbox.image != 'undefined'">
                  <img
                    v-if="giftbox.image.path !== null"
                    :src="
                      'https://sendcloudholidaygifts.com/db/storage/uploads/' +
                      giftbox.image.path
                    "
                    class="card-img-top"
                    alt="..."
                  />
                </div>
              </div>
              <div class="col-md-6 ms-auto">
                <span v-html="giftbox.description"></span>
                <h6>Contains</h6>
                <ul>
                  <li v-for="item in giftbox.contains" v-bind:key="item">
                    {{ item }}
                  </li>
                </ul>

                <div class="d-grid gap-2">
                  <button
                    class="btn btn-primary rounded-pill"
                    v-if="getGiftboxTotal(giftbox) < 1"
                    disabled="disabled"
                  >
                    No longer available
                  </button>
                  <button
                    class="btn btn-primary rounded-pill"
                    v-if="getGiftboxTotal(giftbox) > 0"
                    @click="saveEmployee(giftbox.number)"
                    :disabled="giftbox.number == getEmployee.choice"
                  >
                    Choose this giftbox
                  </button>
                  <button
                    v-if="giftbox.number == getEmployee.choice"
                    type="button"
                    class="btn btn-primary rounded-pill"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="sendToCheckout()"
                  >
                    Checkout your giftbox
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GiftboxDetail",
  props: ["giftbox"],
  data: () => ({}),
  computed: {
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getGiftboxTotals() {
      return this.$store.getters.getGiftboxTotals;
    },
  },
  methods: {
    saveEmployee(giftboxnumber) {
      let employee = this.getEmployee;
      employee.choice = giftboxnumber;
      this.$store.dispatch("saveEmployee", employee);
    },
    sendToCheckout() {
      this.$router.push("/checkout");
    },
    getGiftboxTotal(giftbox) {
      let totals = this.getGiftboxTotals;
      let quantityLeft = giftbox.quantity;
      if (typeof totals[giftbox.number] != "undefined") {
        quantityLeft = giftbox.quantity - totals[giftbox.number];
      }
      return quantityLeft;
    },
  },
};
</script>

<style lang="css" scoped></style>
